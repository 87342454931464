<template>
  <div class="mobile-nav">
    <div class="mobile-nav__nav-logo">
      <a
        href="https://www.mcdonalds.com/"
        class="component-logo navbar-brand"
        data-at="TopLinks:mcdonalds-supreme:McDonald's Startseite"
        data-track="topLinks"
        aria-label="McDonald's Startseite"
      >
        <img
          src="https://mcdportalmediast1.blob.core.windows.net/mcd-portal-prod-backend/mcd-header-footer-navigation-component/images/McDonald_Logo_on_white_desktop.jpg"
          alt=""
          aria-hidden="true"
        />
      </a>
    </div>
    <div class="mobile-nav__menu-icons">
      <button
        v-if="!navOpen"
        class="menu-icon no-ring"
        @click="toggleNav"
        tabindex="0"
        aria-label="Hauptmenü"
        data-bs-toggle="collapse"
        aria-expanded="false"
      >
        <i class="bi bi-list"></i>
      </button>

      <button
        v-if="navOpen"
        @click="toggleNav"
        class="close-icon"
        tabindex="0"
        aria-label="Hauptmenü schließen"
        data-toggle="collapse"
        aria-expanded="true"
      >
        <i class="bi bi-x"></i>
      </button>
    </div>
    <div v-if="navOpen" class="mobile-nav__links-panel">
      <ul class="links">
        <li
          v-for="(topLeftLink, i) in navigationRequestData.topLeftLinks"
          :key="i"
          class="links__item"
        >
          <a
            :href="topLeftLink.url"
            class="link"
            data-at="TopLinks:mcdonalds-supreme:Karriere"
            data-track="Headerlink"
            data-category="Unspecified"
          >
            {{ topLeftLink.label }}
          </a>
        </li>
      </ul>
      <hr aria-hidden="true" />

      <ul class="links first">
        <li class="links__item mobile-menu-link">
          <a
            role="button"
            data-bs-toggle="collapse"
            class="main-link"
            aria-controls="ourMenuMobileSubItemsList"
            data-toggle="collapse"
            aria-expanded="true"
            aria-label="Produkte"
            data-at="TopNav:Produkte"
            data-track="topNav"
            aria-live="polite"
            @click="toggleProducts"
          >
            <span aria-hidden="true">
              {{ navigationRequestData.productsDropdown.label }}
              <i v-if="!productsOpen" class="bi bi-plus"></i>
              <i v-if="productsOpen" class="bi bi-dash"></i>
            </span>
          </a>
          <div
            v-if="productsOpen"
            class="mobile-flyout-menu collapse"
            id="ourMenuMobileSubItemsList"
          >
            <div class="">
              <div class="menulist-flyout">
                <div class="flyout-menu__container">
                  <div class="view-full-menu d-block d-md-none">
                    <a
                      :href="navigationRequestData.popularProducts.url"
                      class="button"
                      data-at="TopNav:Produkte:Beliebte Produkte"
                      data-track="topNav"
                    >
                      {{ navigationRequestData.popularProducts.label }}
                    </a>
                  </div>

                  <ul class="menu-items clearfix">
                    <li
                      v-for="(product, i) in navigationRequestData
                        .productsDropdown.products"
                      :key="i"
                      class="menu-item key-arrow-parent"
                    >
                      <a
                        :href="product.url"
                        class="link key-arrow-move"
                        data-track="topNav"
                      >
                        <img :src="product.image" aria-hidden="true" alt="" />
                        <span class="menu-text">{{ product.label }}</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </li>

        <!--OTHER MAIN LINKS-->
        <li
          v-for="(mainLink, i) in navigationRequestData.mainLinks"
          :key="i"
          class="links__item"
          aria-haspopup="false"
        >
          <a
            :href="mainLink.url"
            class="main-link"
            data-at="TopNav:McCafé"
            data-track="topNav"
            >{{ mainLink.label }}</a
          >
        </li>
      </ul>

      <hr aria-hidden="true" />
      <ul class="links">
        <li class="links__item">
          <a
            :href="navigationRequestData.topRightLinks[0].url"
            class="link"
            target="_self"
            data-at="TopLinks:mcdonalds-supreme:Suche"
            data-track="Headerlink"
          >
            <i class="bi-search"></i>
            {{ navigationRequestData.topRightLinks[0].label }}
          </a>
        </li>

        <li class="links__item">
          <a
            :href="navigationRequestData.topRightLinks[1].url"
            class="link"
            target="_self"
            data-at="TopLinks:mcdonalds-supreme:Restaurants"
            data-track="Headerlink"
          >
            <i class="bi bi-geo-alt-fill"></i>
            <span class="ng-scope">{{
              navigationRequestData.topRightLinks[1].label
            }}</span>
          </a>
        </li>
      </ul>
      <hr aria-hidden="true" />
    </div>
  </div>
</template>

<script>
export default {
  name: "MobileNav",
  props: ["navigationRequestData"],
  components: {},
  data() {
    return {
      navOpen: false,
      productsOpen: false,
    };
  },
  methods: {
    toggleNav() {
      return (this.navOpen = !this.navOpen);
    },
    toggleProducts() {
      return (this.productsOpen = !this.productsOpen);
    },
  },
};
</script>

<style lang="less" scoped>
@color_1: #292929;
@color_2: #006bae;
@color_3: inherit;
@color_4: #db0007;
@font_family_1: speedee Regular;
@font_family_2: inherit;
@font_family_3: icomoon;
@background_color_1: transparent;
@background_color_2: #fff;

.bi-list {
  display: flex;
  color: #000;
}

.mobile-nav {
  box-shadow: 0 0.0625rem 0.5rem 0 rgba(0, 0, 0, 0.04),
    0 0.0625rem 0.3125rem 0 rgba(0, 0, 0, 0.04);
  .mobile-nav__nav-logo {
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    font-weight: 400;
    text-align: left;
    font-size: 16px;
    line-height: 1.42857143;
    color: @color_1;
    font-family: @font_family_1;
    box-sizing: inherit;
    -webkit-font-smoothing: antialiased;
    min-width: 48px;
    min-height: 48px;
    display: inline-block;
    vertical-align: middle;
  }
  .component-logo.navbar-brand {
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    font-weight: 400;
    text-align: left;
    font-family: @font_family_1;
    box-sizing: inherit;
    -webkit-font-smoothing: antialiased;
    background-color: @background_color_1;
    color: @color_2;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    line-height: inherit;
    white-space: nowrap;
    padding: 0 !important;
    margin-bottom: 0 !important;
    height: 48px;
    margin-right: 0;
    img {
      -webkit-text-size-adjust: 100%;
      -webkit-tap-highlight-color: transparent;
      font-weight: 400;
      text-align: left;
      font-family: @font_family_1;
      color: @color_2;
      font-size: 20px;
      line-height: inherit;
      white-space: nowrap;
      box-sizing: inherit;
      -webkit-font-smoothing: antialiased;
      border-style: none;
      vertical-align: middle;
      max-width: 48px;
    }
  }
  .mobile-nav__menu-icons {
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    font-weight: 400;
    text-align: left;
    font-size: 16px;
    line-height: 1.42857143;
    color: @color_1;
    font-family: @font_family_1;
    box-sizing: inherit;
    -webkit-font-smoothing: antialiased;
    width: calc(100% - 55px);
    display: inline-block;
    vertical-align: middle;
    .menu-icon {
      -webkit-text-size-adjust: 100%;
      -webkit-tap-highlight-color: transparent;
      box-sizing: inherit;
      -webkit-font-smoothing: antialiased;
      border-radius: 0;
      margin: 0;
      font-family: @font_family_2;
      line-height: inherit;
      overflow: visible;
      text-transform: none;
      -webkit-appearance: button;
      border: none;
      background-color: @background_color_1;
      font-size: 22px;
      cursor: pointer;
      width: 30px;
      display: inline-block;
      padding: 3px;
      padding-left: 10px;
      margin-top: 6px;
      height: 100%;
      color: @color_3;
      position: relative;
      .bi.bi-list {
        -webkit-text-size-adjust: 100%;
        -webkit-tap-highlight-color: transparent;
        font-size: 22px;
        cursor: pointer;
        color: @color_3;
        box-sizing: inherit;
        speak: none;
        font-style: normal;
        font-weight: 400;
        font-feature-settings: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        font-size: 26px;
      }
    }
    .close-icon {
      -webkit-text-size-adjust: 100%;
      -webkit-tap-highlight-color: transparent;
      box-sizing: inherit;
      -webkit-font-smoothing: antialiased;
      border-radius: 0;
      margin: 0;
      font-family: @font_family_2;
      line-height: inherit;
      overflow: visible;
      text-transform: none;
      -webkit-appearance: button;
      color: @color_3;
      border: none;
      background-color: @background_color_1;
      padding-top: 10px;
      font-size: 22px;
      cursor: pointer;
      padding-left: 0;
      padding-right: 10px;
      float: right;
      .bi-x {
        -webkit-text-size-adjust: 100%;
        -webkit-tap-highlight-color: transparent;
        color: @color_3;
        font-size: 22px;
        cursor: pointer;
        box-sizing: inherit;
        font-family: @font_family_3 !important;
        speak: none;
        font-style: normal;
        font-weight: 400;
        font-feature-settings: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        font-size: 30px;
      }
    }
  }
  .mobile-nav__links-panel {
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    font-weight: 400;
    text-align: left;
    font-size: 16px;
    line-height: 1.42857143;
    color: @color_1;
    font-family: @font_family_1;
    box-sizing: inherit;
    -webkit-font-smoothing: antialiased;
    padding-left: 64px;
    padding-right: 13px;
    min-width: 243px;
    margin-top: 40px;
    padding-bottom: 13px;
    .links {
      -webkit-text-size-adjust: 100%;
      -webkit-tap-highlight-color: transparent;
      font-weight: 400;
      text-align: left;
      font-size: 16px;
      line-height: 1.42857143;
      color: @color_1;
      font-family: @font_family_1;
      box-sizing: inherit;
      -webkit-font-smoothing: antialiased;
      margin: 0;
      padding: 0;
      margin-top: 20px;
      .links__item {
        -webkit-text-size-adjust: 100%;
        -webkit-tap-highlight-color: transparent;
        font-weight: 400;
        font-size: 16px;
        line-height: 1.42857143;
        color: @color_1;
        font-family: @font_family_1;
        box-sizing: inherit;
        -webkit-font-smoothing: antialiased;
        list-style: none;
        position: static;
        display: block;
        margin-bottom: 22px;
        padding-right: 2px;
        margin-top: 1px;
        a {
          -webkit-text-size-adjust: 100%;
          -webkit-tap-highlight-color: transparent;
          font-weight: 400;
          font-family: @font_family_1;
          list-style: none;
          box-sizing: inherit;
          -webkit-font-smoothing: antialiased;
          background-color: @background_color_1;
          text-decoration: none;
          color: @color_1;
          display: block;
          font-size: 14px;
          line-height: 22px;
        }
      }
      .links__item.mobile-menu-link {
        -webkit-text-size-adjust: 100%;
        -webkit-tap-highlight-color: transparent;
        font-weight: 400;
        font-size: 16px;
        line-height: 1.42857143;
        color: @color_1;
        font-family: @font_family_1;
        box-sizing: inherit;
        -webkit-font-smoothing: antialiased;
        list-style: none;
        position: static;
        display: block;
        margin-bottom: 22px;
        padding-right: 2px;
        margin-top: 1px;
        .main-link {
          -webkit-text-size-adjust: 100%;
          -webkit-tap-highlight-color: transparent;
          font-weight: 400;
          font-family: @font_family_1;
          list-style: none;
          box-sizing: inherit;
          -webkit-font-smoothing: antialiased;
          background-color: @background_color_1;
          text-decoration: none;
          color: @color_1;
          display: block;
          line-height: 26px;
          padding: 0;
          font-size: 16px;
          .bi-plus {
            -webkit-text-size-adjust: 100%;
            -webkit-tap-highlight-color: transparent;
            list-style: none;
            cursor: pointer;
            color: @color_1;
            box-sizing: inherit;
            font-family: @font_family_3 !important;
            speak: none;
            font-style: normal;
            font-weight: 400;
            font-feature-settings: normal;
            font-variant: normal;
            text-transform: none;
            line-height: 1;
            -webkit-font-smoothing: antialiased;
            float: right !important;
            display: block;
            font-size: 30px;
            margin-top: -2px;
          }
          .bi-dash {
            -webkit-text-size-adjust: 100%;
            -webkit-tap-highlight-color: transparent;
            list-style: none;
            cursor: pointer;
            color: @color_1;
            box-sizing: inherit;
            font-family: @font_family_3 !important;
            speak: none;
            font-style: normal;
            font-weight: 400;
            font-feature-settings: normal;
            font-variant: normal;
            text-transform: none;
            line-height: 1;
            -webkit-font-smoothing: antialiased;
            float: right !important;
            font-size: 24px;
            margin-top: 2px;
            font-size: 30px;
            margin-top: -2px;
          }
        }
        .main-link.collapsed {
          -webkit-text-size-adjust: 100%;
          -webkit-tap-highlight-color: transparent;
          font-weight: 400;
          font-family: @font_family_1;
          list-style: none;
          box-sizing: inherit;
          -webkit-font-smoothing: antialiased;
          background-color: @background_color_1;
          text-decoration: none;
          cursor: pointer;
          color: @color_1;
          display: block;
          line-height: 26px;
          padding: 0;
          font-size: 16px;
        }
      }
    }
  }
  .links.first {
    .links__item {
      -webkit-text-size-adjust: 100%;
      -webkit-tap-highlight-color: transparent;
      font-weight: 400;
      font-size: 16px;
      line-height: 1.42857143;
      color: @color_1;
      font-family: @font_family_1;
      box-sizing: inherit;
      -webkit-font-smoothing: antialiased;
      list-style: none;
      position: static;
      display: block;
      margin-top: 30px;
      margin-bottom: 22px;
      padding-right: 2px;
      a {
        -webkit-text-size-adjust: 100%;
        -webkit-tap-highlight-color: transparent;
        font-weight: 400;
        font-family: @font_family_1;
        list-style: none;
        box-sizing: inherit;
        -webkit-font-smoothing: antialiased;
        background-color: @background_color_1;
        text-decoration: none;
        color: @color_1;
        display: block;
        font-size: 16px;
        line-height: 26px;
        padding: 0;
      }
    }
  }
  .bi-search {
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    list-style: none;
    color: @color_1;
    box-sizing: inherit;
    font-family: @font_family_3 !important;
    speak: none;
    font-style: normal;
    font-weight: 400;
    font-feature-settings: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    float: right !important;
    cursor: pointer;
    font-size: 18px;
  }
  .bi-geo-alt-fill {
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    list-style: none;
    color: @color_1;
    box-sizing: inherit;
    font-family: @font_family_3 !important;
    speak: none;
    font-style: normal;
    font-weight: 400;
    font-feature-settings: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    float: right !important;
    cursor: pointer;
    font-size: 22px;
    color: @color_4;
  }
  .menu-items.clearfix {
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.42857143;
    color: @color_1;
    font-family: @font_family_1;
    list-style: none;
    box-sizing: inherit;
    -webkit-font-smoothing: antialiased;
    margin: 0;
    padding: 0;
    .menu-item.key-arrow-parent {
      -webkit-text-size-adjust: 100%;
      -webkit-tap-highlight-color: transparent;
      font-weight: 400;
      font-size: 16px;
      line-height: 1.42857143;
      color: @color_1;
      font-family: @font_family_1;
      box-sizing: inherit;
      -webkit-font-smoothing: antialiased;
      list-style: none;
      .link.key-arrow-move {
        -webkit-text-size-adjust: 100%;
        -webkit-tap-highlight-color: transparent;
        font-weight: 400;
        font-family: @font_family_1;
        list-style: none;
        box-sizing: inherit;
        -webkit-font-smoothing: antialiased;
        background-color: @background_color_1;
        text-decoration: none;
        display: block;
        line-height: 20px;
        font-size: 16px;
        color: @color_1;
        img {
          -webkit-text-size-adjust: 100%;
          -webkit-tap-highlight-color: transparent;
          font-weight: 400;
          font-family: @font_family_1;
          list-style: none;
          line-height: 20px;
          font-size: 16px;
          color: @color_1;
          box-sizing: inherit;
          -webkit-font-smoothing: antialiased;
          border-style: none;
          vertical-align: middle;
          height: 60px;
          margin-right: 15px;
        }
        .menu-text {
          -webkit-text-size-adjust: 100%;
          -webkit-tap-highlight-color: transparent;
          font-weight: 400;
          font-family: @font_family_1;
          list-style: none;
          line-height: 20px;
          font-size: 16px;
          color: @color_1;
          box-sizing: inherit;
          -webkit-font-smoothing: antialiased;
        }
      }
    }
  }
  .view-full-menu.d-none.d-md-block {
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.42857143;
    color: @color_1;
    font-family: @font_family_1;
    list-style: none;
    box-sizing: inherit;
    -webkit-font-smoothing: antialiased;
    display: block !important;
    margin: 37px 0 41px;
    a {
      -webkit-text-size-adjust: 100%;
      -webkit-tap-highlight-color: transparent;
      font-weight: 400;
      font-family: @font_family_1;
      list-style: none;
      box-sizing: inherit;
      -webkit-font-smoothing: antialiased;
      background: #fff;
      border: 1px solid #adadad;
      border-radius: 4px;
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.08);
      line-height: 20px;
      outline: none;
      padding: 13px 41px;
      text-align: center;
      display: inline-block;
      font-size: 16px;
      color: @color_1;
      text-decoration: none;
    }
  }
  .closeMenuButton {
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    box-sizing: inherit;
    -webkit-font-smoothing: antialiased;
    border-radius: 0;
    margin: 0;
    font-family: @font_family_2;
    line-height: inherit;
    text-transform: none;
    -webkit-appearance: button;
    color: @color_3;
    cursor: pointer;
    height: 1px;
    width: 1px;
    text-align: left;
    overflow: hidden;
    font-size: 20px;
    text-decoration: underline;
    border: none;
    background-color: @background_color_2;
    top: auto;
    left: auto;
    padding: 0;
    position: absolute;
    .closeMenu {
      -webkit-text-size-adjust: 100%;
      -webkit-tap-highlight-color: transparent;
      color: @color_3;
      cursor: pointer;
      text-align: left;
      box-sizing: inherit;
      font-family: @font_family_3 !important;
      speak: none;
      font-style: normal;
      font-weight: 400;
      font-feature-settings: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      padding-right: 12px;
      font-size: 20px;
    }
  }
}
.mobile-nav__links-panel {
  hr {
    margin-top: 27px;
    margin-bottom: 27px;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    box-sizing: content-box;
    height: 0;
    overflow: visible;
  }
}
.mobile-flyout-menu {
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.42857143;
  color: @color_1;
  font-family: @font_family_1;
  list-style: none;
  box-sizing: inherit;
  -webkit-font-smoothing: antialiased;
  .menulist-flyout {
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.42857143;
    color: @color_1;
    font-family: @font_family_1;
    list-style: none;
    box-sizing: inherit;
    -webkit-font-smoothing: antialiased;
    .flyout-menu__container {
      -webkit-text-size-adjust: 100%;
      -webkit-tap-highlight-color: transparent;
      font-weight: 400;
      font-size: 16px;
      line-height: 1.42857143;
      color: @color_1;
      font-family: @font_family_1;
      list-style: none;
      box-sizing: inherit;
      -webkit-font-smoothing: antialiased;
      .view-full-menu.d-block.d-md-none {
        -webkit-text-size-adjust: 100%;
        -webkit-tap-highlight-color: transparent;
        font-weight: 400;
        font-size: 16px;
        line-height: 1.42857143;
        color: @color_1;
        font-family: @font_family_1;
        list-style: none;
        box-sizing: inherit;
        -webkit-font-smoothing: antialiased;
        margin: 37px 0 25px;

        a {
          -webkit-text-size-adjust: 100%;
          -webkit-tap-highlight-color: transparent;
          font-weight: 400;
          font-family: @font_family_1;
          list-style: none;
          box-sizing: inherit;
          -webkit-font-smoothing: antialiased;
          background: #fff;
          border: 1px solid #adadad;
          border-radius: 4px;
          box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.08);
          line-height: 20px;
          outline: none;
          padding: 13px 41px;
          text-align: center;
          display: inline-block;
          font-size: 16px;
          color: @color_1;
          text-decoration: none;
        }
      }
    }
  }
}
.mobile-flyout-menu.collapse {
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.42857143;
  color: @color_1;
  font-family: @font_family_1;
  list-style: none;
  box-sizing: inherit;
  -webkit-font-smoothing: antialiased;
}
</style>
