<template>
  <header
    class="header"
    role="banner"
    data-ng-init="init(true,false)"
    id="header-jcr:content-2088208396"
  >
    <nav
      v-if="showNavigationWhenDataLoaded"
      data-component="globalNavigation"
      class="mcd-global-nav"
      aria-label="Navigation"
      role="navigation"
    >
      <DesktopNav class="desktopNav" :navigationRequestData="navigationItems" />
      <MobileNav class="mobileNav" :navigationRequestData="navigationItems" />
    </nav>
  </header>
</template>

<script>
import DesktopNav from "@/components/header/DesktopNav";
import MobileNav from "@/components/header/MobileNav";

import axios from "axios";

export default {
  name: "McdHeaderComponent",
  props: {
    msg: String,
  },
  components: {
    DesktopNav,
    MobileNav,
  },
  data() {
    return {
      navigationItems: {},
      showNavigationWhenDataLoaded: false,
    };
  },
  created() {
    axios
      // .get("/json/navigation.json")
      .get(
        "https://mcdportalmediast1.blob.core.windows.net/mcd-portal-prod-backend/mcd-header-footer-navigation-component/json/navigation.json"
      )
      .then((response) => {
        this.navigationItems = response.data;
        this.showNavigationWhenDataLoaded = true;
      });
  },
  mounted() {
    const css = `
       @import url("https://mcdportalmediast1.blob.core.windows.net/mcd-portal-prod-backend/mcd-header-footer-navigation-component/iconfont/bs-icons.css");

      @font-face {
        font-family: "Speedee";
        src: url('https://mcdportalmediast1.blob.core.windows.net/mcd-portal-prod-backend/mcd-header-footer-navigation-component/fonts/Speedee_W_Lt.woff2') format('woff2');
        font-weight: 300;
        font-style: normal;
      }

      @font-face {
          font-family: "Speedee Regular";
          src: url('https://mcdportalmediast1.blob.core.windows.net/mcd-portal-prod-backend/mcd-header-footer-navigation-component/fonts/Speedee_W_Rg.woff2') format('woff2');
          src: url('https://mcdportalmediast1.blob.core.windows.net/mcd-portal-prod-backend/mcd-header-footer-navigation-component/fonts/Speedee_W_Rg.ttf') format('truetype');;
          font-weight: 400;
          font-style: normal;
        }

      @font-face {
          font-family: "Speedee Bold";
          src: url('https://mcdportalmediast1.blob.core.windows.net/mcd-portal-prod-backend/mcd-header-footer-navigation-component/fonts/Speedee_W_Bd.woff2') format('woff2');
          src: url('https://mcdportalmediast1.blob.core.windows.net/mcd-portal-prod-backend/mcd-header-footer-navigation-component/fonts/Speedee_W_Bd.ttf') format('truetype');
          font-weight: 700;
          font-style: normal;
        }`;

    // if our style is already injected we do not need to inject it a second time
    if (!document.getElementById("myCustomInjectedStyle")) {
      const head = document.head || document.getElementsByTagName("head")[0];
      const style = document.createElement("style");
      style.id = "myCustomInjectedStyle";
      style.type = "text/css";
      style.innerText = css;
      head.appendChild(style);
    }
  },
};
</script>

<style>
@import url("https://mcdportalmediast1.blob.core.windows.net/mcd-portal-prod-backend/mcd-header-footer-navigation-component/iconfont/bs-icons.css");

.mobile-nav {
  display: none;
}

header {
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  font-weight: 400;
  text-align: left;
  font-family: Speedee Regular;
  font-size: 16px;
  line-height: 1.42857143;
  color: #292929;
  box-sizing: inherit;
  -webkit-font-smoothing: antialiased;
  display: block;
  width: 100%;
}

.mcd-global-nav {
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  font-weight: 400;
  text-align: left;
  font-size: 16px;
  line-height: 1.42857143;
  color: #292929;
  box-sizing: inherit;
  -webkit-font-smoothing: antialiased;
  display: block;
  background-color: #fff;
  font-family: Speedee Regular;
  z-index: 299;
  overflow: visible;
  margin-bottom: 3px;
}

.desktopNav,
.mobileNav {
  display: none !important;
}

@media screen and (max-width: 1119px) {
  .mobileNav {
    display: block !important;
  }
}

@media screen and (min-width: 1119px) {
  .desktopNav {
    display: block !important;
  }
}
</style>
