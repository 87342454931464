<template>
  <div class="desktop-nav">
    <div class="mcd-global-nav__container container mcd-page-header">
      <div class="nav__logo">
        <a
          href="https://www.mcdonalds.com/"
          class="component-logo navbar-brand"
          data-at="TopLinks:Home:McDonald's Startseite"
          data-track="topLinks"
          aria-label="McDonald's Startseite"
        >
          <img
            src="https://mcdportalmediast1.blob.core.windows.net/mcd-portal-prod-backend/mcd-header-footer-navigation-component/images/McDonald_Logo_on_white_desktop.jpg"
            alt
            aria-hidden="true"
          />
        </a>
      </div>
      <div class="secondary__links row">
        <div
          class="left__rail col-lg-5"
          data-ng-class="{'left__rail-order': 'false' == 'true'}"
        >
          <!-- ##### TOP LEFT LINKS ##### -->
          <ul class="links">
            <li
              v-for="(topLeftLink, i) in navigationRequestData.topLeftLinks"
              :key="i"
              class="links__item"
            >
              <a :href="topLeftLink.url" class="link">
                {{ topLeftLink.label }}
              </a>
            </li>
          </ul>
        </div>
        <div class="right__rail col-lg-7">
          <!-- ##### TOP RIGHT LINKS ##### -->
          <ul class="links">
            <li class="links__item">
              <a
                :href="navigationRequestData.topRightLinks[0].url"
                class="link"
                target="_self"
                data-at="TopLinks:Home:Suche"
                data-track="Headerlink"
              >
                <span class="bi bi-search"></span>
                <span class="item-text">{{
                  navigationRequestData.topRightLinks[0].label
                }}</span>
              </a>
            </li>
            <li class="links__item">
              <a
                :href="navigationRequestData.topRightLinks[1].url"
                class="link"
                target="_self"
                data-at="TopLinks:Home:Restaurants"
                data-track="Headerlink"
              >
                <span class="bi bi-geo-alt-fill"></span>
                <span ng-if="!firstRestaurantName" class="item-text ng-scope">
                  {{ navigationRequestData.topRightLinks[1].label }}
                </span>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="primary__links">
        <ul class="links" aria-label="Hauptnavigation">
          <!-- ##### PRODUCTS LINKS ##### -->
          <li class="links__item desktop-menu-link">
            <a
              @click="toggleDropdown"
              v-click-outside="closeDropdownIfAlreadyOpen"
              class="toggle-desktop-menu main-link"
              data-display="static"
              aria-controls="ourMenuSubItemsList"
              data-toggle="dropdown"
              aria-expanded="false"
              role="button"
              data-at="TopNav:Produkte"
              data-track="topNav"
            >
              <span :class="showDropdown ? 'active' : ''">{{
                navigationRequestData.productsDropdown.label
              }}</span>
              <i class="bi bi-chevron-down" v-if="!showDropdown" />
              <i class="bi bi-chevron-up" v-else style="line-height: 1.2" />
            </a>
            <div
              v-if="showDropdown"
              class="flyout-menu dropdown-menu"
              id="ourMenuSubItemsList"
            >
              <div>
                <div class="menulist-flyout">
                  <div class="flyout-menu__container">
                    <ul class="menu-items clearfix">
                      <li
                        v-for="(product, i) in navigationRequestData
                          .productsDropdown.products"
                        :key="i"
                        class="menu-item key-arrow-parent"
                      >
                        <a
                          :href="product.url"
                          class="link key-arrow-move"
                          data-track="topNav"
                        >
                          <img :src="product.image" aria-hidden="true" alt="" />
                          <span class="menu-text">{{ product.label }}</span>
                        </a>
                      </li>
                    </ul>
                    <div class="view-full-menu d-none d-md-block">
                      <a
                        :href="navigationRequestData.popularProducts.url"
                        class="button"
                        data-at="TopNav:Produkte:Beliebte Produkte"
                        data-track="topNav"
                        >{{ navigationRequestData.popularProducts.label }}</a
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <!-- ##### OTHER MAIN LINKS ##### -->
          <li
            v-for="(mainLink, i) in navigationRequestData.mainLinks"
            :key="i"
            class="links__item"
            aria-haspopup="false"
          >
            <a :href="mainLink.url" class="main-link" data-track="topNav">{{
              mainLink.label
            }}</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import vClickOutside from "v-click-outside";
Vue.use(vClickOutside);
export default {
  name: "DesktopNav",
  props: ["navigationRequestData"],
  data() {
    return {
      showDropdown: false,
    };
  },
  methods: {
    toggleDropdown() {
      return (this.showDropdown = !this.showDropdown);
    },
    closeDropdownIfAlreadyOpen() {
      this.showDropdown ? (this.showDropdown = false) : null;
    },
  },
  mounted() {},
};
</script>

<style lang="less" scoped>
@color_1: #292929;
@color_2: #006bae;
@color_3: #db0007;
@color_4: #212529;
@font_family_1: Speedee Regular;
@font_family_2: icomoon;
@font_family_3: Speedee Bold;
@background_color_1: transparent;
@background_color_2: #fff;

.desktop-nav {
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  font-weight: 400;
  text-align: left;
  font-size: 16px;
  line-height: 1.42857143;
  color: @color_1;
  font-family: @font_family_1;
  box-sizing: inherit;
  -webkit-font-smoothing: antialiased;
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.04), 0 1px 5px 0 rgba(0, 0, 0, 0.04);
  display: block;
  .mcd-global-nav__container.container.mcd-page-header {
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    font-weight: 400;
    text-align: left;
    font-size: 16px;
    line-height: 1.42857143;
    color: @color_1;
    font-family: @font_family_1;
    box-sizing: inherit;
    -webkit-font-smoothing: antialiased;
    width: 100%;
    margin: 0 auto;
    max-width: 1170px;
    padding: 0;
    overflow: hidden;
  }
  .nav__logo {
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    font-weight: 400;
    text-align: left;
    font-size: 16px;
    line-height: 1.42857143;
    color: @color_1;
    font-family: @font_family_1;
    box-sizing: inherit;
    -webkit-font-smoothing: antialiased;
    min-height: 1px;
    padding-right: 0;
    padding-left: 0;
    float: left;
    width: 108px;
    height: 108px;
    position: relative;
    margin-right: 57px;
    a {
      -webkit-text-size-adjust: 100%;
      -webkit-tap-highlight-color: transparent;
      font-weight: 400;
      text-align: left;
      font-family: @font_family_1;
      box-sizing: inherit;
      -webkit-font-smoothing: antialiased;
      background-color: @background_color_1;
      color: @color_2;
      text-decoration: none;
      display: inline-block;
      padding-top: 5px;
      padding-bottom: 5px;
      margin-right: 16px;
      font-size: 20px;
      line-height: inherit;
      white-space: nowrap;
      width: 108px;
      height: 108px;
      img {
        -webkit-text-size-adjust: 100%;
        -webkit-tap-highlight-color: transparent;
        font-weight: 400;
        text-align: left;
        font-family: @font_family_1;
        color: @color_2;
        font-size: 20px;
        line-height: inherit;
        white-space: nowrap;
        box-sizing: inherit;
        -webkit-font-smoothing: antialiased;
        border-style: none;
        vertical-align: middle;
        position: absolute;
        left: 0;
        top: 0;
        width: 108px;
        height: 108px;
      }
    }
  }
  .secondary__links {
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    font-weight: 400;
    text-align: left;
    font-size: 16px;
    line-height: 1.42857143;
    color: @color_1;
    font-family: @font_family_1;
    box-sizing: inherit;
    -webkit-font-smoothing: antialiased;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    min-height: 1px;
    padding-right: 0;
    padding-left: 0;
    margin-top: 15px;
    margin-right: 0;
    margin-left: 0;
    max-height: 40px;
    -webkit-box-align: center;
    align-items: center;
    float: left;
    width: 82.5%;
  }
  .left__rail {
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    font-weight: 400;
    text-align: left;
    font-size: 16px;
    line-height: 1.42857143;
    color: @color_1;
    font-family: @font_family_1;
    box-sizing: inherit;
    -webkit-font-smoothing: antialiased;
    position: relative;
    width: 100%;
    -webkit-box-flex: 0;
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
    padding: 0;
    .links {
      -webkit-text-size-adjust: 100%;
      -webkit-tap-highlight-color: transparent;
      font-weight: 400;
      text-align: left;
      font-size: 16px;
      line-height: 1.42857143;
      color: @color_1;
      font-family: @font_family_1;
      box-sizing: inherit;
      -webkit-font-smoothing: antialiased;
      margin: 0;
      padding: 0;
      display: flex;
      -webkit-box-align: center;
      align-items: center;
    }
    .links__item {
      -webkit-text-size-adjust: 100%;
      -webkit-tap-highlight-color: transparent;
      font-weight: 400;
      font-size: 16px;
      line-height: 1.42857143;
      color: @color_1;
      font-family: @font_family_1;
      box-sizing: inherit;
      -webkit-font-smoothing: antialiased;
      display: inline-block;
      list-style: none;
      padding-right: 30px;
      position: static;
      a {
        -webkit-text-size-adjust: 100%;
        -webkit-tap-highlight-color: transparent;
        font-weight: 400;
        font-family: @font_family_1;
        list-style: none;
        box-sizing: inherit;
        -webkit-font-smoothing: antialiased;
        background-color: @background_color_1;
        text-decoration: none;
        line-height: 16px;
        font-size: 14px;
        color: @color_1;
      }
    }
  }
  .right__rail {
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.42857143;
    color: @color_1;
    font-family: @font_family_1;
    box-sizing: inherit;
    -webkit-font-smoothing: antialiased;
    position: relative;
    width: 100%;
    -webkit-box-flex: 0;
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
    padding: 0;
    text-align: right;
    right: 0;
    .links {
      -webkit-text-size-adjust: 100%;
      -webkit-tap-highlight-color: transparent;
      font-weight: 400;
      font-size: 16px;
      line-height: 1.42857143;
      color: @color_1;
      font-family: @font_family_1;
      text-align: right;
      box-sizing: inherit;
      -webkit-font-smoothing: antialiased;
      margin: 0;
      padding: 0;
      display: flex;
      -webkit-box-align: center;
      align-items: center;
      float: right;
      .links__item {
        -webkit-text-size-adjust: 100%;
        -webkit-tap-highlight-color: transparent;
        font-weight: 400;
        font-size: 16px;
        line-height: 1.42857143;
        color: @color_1;
        font-family: @font_family_1;
        box-sizing: inherit;
        -webkit-font-smoothing: antialiased;
        display: inline-block;
        list-style: none;
        padding-right: 30px;
        position: static;
        a {
          -webkit-text-size-adjust: 100%;
          -webkit-tap-highlight-color: transparent;
          font-weight: 400;
          font-family: @font_family_1;
          list-style: none;
          box-sizing: inherit;
          -webkit-font-smoothing: antialiased;
          background-color: @background_color_1;
          text-decoration: none;
          line-height: 16px;
          font-size: 14px;
          color: @color_1;
          display: flex;
          align-items: center;

          .bi-search {
            -webkit-text-size-adjust: 100%;
            -webkit-tap-highlight-color: transparent;
            list-style: none;
            color: @color_1;
            box-sizing: inherit;
            font-family: @font_family_2 !important;
            speak: none;
            font-style: normal;
            font-weight: 400;
            font-feature-settings: normal;
            font-variant: normal;
            text-transform: none;
            -webkit-font-smoothing: antialiased;
            line-height: 1.16px;
            font-size: 16px;
            margin-right: 4px;
            &:before {
              line-height: 1.2;
            }
          }
          .item-text {
            -webkit-text-size-adjust: 100%;
            -webkit-tap-highlight-color: transparent;
            font-weight: 400;
            font-family: @font_family_1;
            list-style: none;
            line-height: 16px;
            font-size: 14px;
            color: @color_1;
            box-sizing: inherit;
            -webkit-font-smoothing: antialiased;
          }
        }
      }
    }
  }
  .bi-geo-alt-fill {
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    list-style: none;
    color: @color_1;
    box-sizing: inherit;
    font-family: @font_family_2 !important;
    speak: none;
    font-style: normal;
    font-weight: 400;
    font-feature-settings: normal;
    font-variant: normal;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    line-height: 1.16px;
    color: @color_3;
    font-size: 20px;
  }
  .primary__links {
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    font-weight: 400;
    text-align: left;
    font-size: 16px;
    line-height: 1.42857143;
    color: @color_1;
    font-family: @font_family_1;
    box-sizing: inherit;
    -webkit-font-smoothing: antialiased;
    min-height: 1px;
    padding-right: 0;
    padding-left: 0;
    margin-top: 14px;
    float: left;
    width: 82.5%;
    position: static;
    .links {
      -webkit-text-size-adjust: 100%;
      -webkit-tap-highlight-color: transparent;
      font-weight: 400;
      text-align: left;
      font-size: 16px;
      line-height: 1.42857143;
      color: @color_1;
      font-family: @font_family_1;
      box-sizing: inherit;
      -webkit-font-smoothing: antialiased;
      margin: 0;
      padding: 0;
      .links__item {
        -webkit-text-size-adjust: 100%;
        -webkit-tap-highlight-color: transparent;
        font-weight: 400;
        font-size: 16px;
        line-height: 1.42857143;
        color: @color_1;
        font-family: @font_family_1;
        box-sizing: inherit;
        -webkit-font-smoothing: antialiased;
        display: inline-block;
        list-style: none;
        position: static;
        padding-right: 25px;
        a.main-link {
          -webkit-text-size-adjust: 100%;
          -webkit-tap-highlight-color: transparent;
          font-weight: 400;
          font-family: @font_family_1;
          list-style: none;
          box-sizing: inherit;
          -webkit-font-smoothing: antialiased;
          background-color: @background_color_1;
          text-decoration: none;
          cursor: pointer;
          color: @color_1;
          line-height: 22px;
          //display: block;
          font-size: 18px;
          margin-top: 16px;
          padding-bottom: 23px;
          width: 105%;
          font-size: 1pc;
          display: flex;
          align-items: end;
        }
        a {
          > i.bi {
            -webkit-text-size-adjust: 100%;
            -webkit-tap-highlight-color: transparent;
            list-style: none;
            cursor: pointer;
            color: @color_1;
            font-size: 18px;
            box-sizing: inherit;
            font-family: @font_family_2 !important;
            speak: none;
            font-style: normal;
            font-weight: 400;
            font-feature-settings: normal;
            font-variant: normal;
            text-transform: none;
            line-height: 1;
            -webkit-font-smoothing: antialiased;
            font-size: 16px;
            margin-left: 5px;
          }
          > span.active {
            font-family: @font_family_3;
          }
        }
      }
      .links__item.desktop-menu-link {
        -webkit-text-size-adjust: 100%;
        -webkit-tap-highlight-color: transparent;
        font-weight: 400;
        font-size: 16px;
        line-height: 1.42857143;
        color: @color_1;
        font-family: @font_family_1;
        box-sizing: inherit;
        -webkit-font-smoothing: antialiased;
        list-style: none;
        position: static;
        display: inline-block;
        padding-right: 25px;
      }
    }
  }
  .flyout-menu.dropdown-menu {
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    font-weight: 400;
    line-height: 1.42857143;
    font-family: @font_family_1;
    box-sizing: inherit;
    -webkit-font-smoothing: antialiased;
    min-width: 160px;
    font-size: 16px;
    color: @color_4;
    text-align: left;
    list-style: none;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    min-height: 1px;
    background-color: @background_color_2;
    box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.04), 0 1px 5px 0 rgba(0, 0, 0, 0.04);
    margin: 0;
    position: absolute;
    top: auto;
    left: 0;
    float: left;
    width: 100%;
    padding: 44px 20px 50px;
    display: block;
    z-index: 10000;
  }
  .menulist-flyout {
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    font-weight: 400;
    line-height: 1.42857143;
    font-family: @font_family_1;
    font-size: 16px;
    color: @color_4;
    text-align: left;
    list-style: none;
    box-sizing: inherit;
    -webkit-font-smoothing: antialiased;
    z-index: 1000;
  }
  .flyout-menu__container {
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    font-weight: 400;
    line-height: 1.42857143;
    font-size: 16px;
    color: @color_4;
    text-align: left;
    list-style: none;
    box-sizing: inherit;
    -webkit-font-smoothing: antialiased;
    width: 1020px;
    margin: auto;
    font-family: @font_family_1;
  }
  .view-full-menu {
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    font-weight: 400;
    line-height: 1.42857143;
    font-size: 16px;
    color: @color_4;
    list-style: none;
    font-family: @font_family_1;
    box-sizing: inherit;
    -webkit-font-smoothing: antialiased;
    display: none !important;
    text-align: center;
    padding-top: 54px;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    font-weight: 400;
    line-height: 1.42857143;
    font-size: 16px;
    color: @color_4;
    list-style: none;
    font-family: @font_family_1;
    box-sizing: inherit;
    -webkit-font-smoothing: antialiased;
    display: block !important;
    text-align: center;
    padding-top: 54px;
    a {
      -webkit-text-size-adjust: 100%;
      -webkit-tap-highlight-color: transparent;
      font-weight: 400;
      list-style: none;
      font-family: @font_family_1;
      box-sizing: inherit;
      -webkit-font-smoothing: antialiased;
      background: #fff;
      border: 1px solid #adadad;
      border-radius: 4px;
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.08);
      line-height: 20px;
      outline: none;
      padding: 13px 25px;
      text-align: center;
      display: inline-block;
      font-size: 16px;
      min-width: 162px;
      color: @color_1;
      text-decoration: none;
    }
    a.button {
      -webkit-text-size-adjust: 100%;
      -webkit-tap-highlight-color: transparent;
      font-weight: 400;
      list-style: none;
      font-family: @font_family_1;
      box-sizing: inherit;
      -webkit-font-smoothing: antialiased;
      background: #fff;
      border: 1px solid #adadad;
      border-radius: 4px;
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.08);
      line-height: 20px;
      outline: none;
      padding: 13px 25px;
      text-align: center;
      display: inline-block;
      font-size: 16px;
      min-width: 162px;
      color: @color_1;
      text-decoration: none;
    }
  }
  .flyout-menu {
    .menulist-flyout {
      .menu-items {
        -webkit-text-size-adjust: 100%;
        -webkit-tap-highlight-color: transparent;
        font-weight: 400;
        line-height: 1.42857143;
        font-size: 16px;
        color: @color_4;
        text-align: left;
        list-style: none;
        font-family: @font_family_1;
        box-sizing: inherit;
        -webkit-font-smoothing: antialiased;
        margin: 0;
        padding: 0;
        column-count: 3;
        li.menu-item {
          -webkit-text-size-adjust: 100%;
          -webkit-tap-highlight-color: transparent;
          font-weight: 400;
          line-height: 1.42857143;
          font-size: 16px;
          color: @color_4;
          font-family: @font_family_1;
          box-sizing: inherit;
          -webkit-font-smoothing: antialiased;
          list-style: none;
          padding: 0;
          display: inherit;
          width: 322px;
          a {
            -webkit-text-size-adjust: 100%;
            -webkit-tap-highlight-color: transparent;
            font-weight: 400;
            font-family: @font_family_1;
            list-style: none;
            box-sizing: inherit;
            -webkit-font-smoothing: antialiased;
            background-color: @background_color_1;
            text-decoration: none;
            display: block;
            color: @color_1;
            font-size: 16px;
            line-height: 22px;
            img {
              -webkit-text-size-adjust: 100%;
              -webkit-tap-highlight-color: transparent;
              font-weight: 400;
              font-family: @font_family_1;
              list-style: none;
              color: @color_1;
              font-size: 16px;
              line-height: 22px;
              box-sizing: inherit;
              -webkit-font-smoothing: antialiased;
              border-style: none;
              vertical-align: middle;
              margin: 0 14px 0 0;
              height: 80px;
              width: 80px;
            }
            span.menu-text {
              -webkit-text-size-adjust: 100%;
              -webkit-tap-highlight-color: transparent;
              font-weight: 400;
              font-family: @font_family_1;
              list-style: none;
              color: @color_1;
              font-size: 16px;
              line-height: 22px;
              box-sizing: inherit;
              -webkit-font-smoothing: antialiased;
            }
          }
        }
      }
    }
  }
}
</style>
